@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add any custom styles below this line */

body {
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.message p {
  margin-bottom: 0.5rem;
}

.message strong {
  font-weight: 600;
}

.message em {
  font-style: italic;
}

.message.assistant {
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  padding: 1rem;
}

.message.user {
  background-color: #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}

.whitespace-normal {
  white-space: normal;
}

@keyframes wave {
  0% {
    transform: translateY(100%) rotate(45deg);
  }
  100% {
    transform: translateY(-100%) rotate(45deg);
  }
}

.animate-wave {
  background: linear-gradient(45deg, transparent 0%, rgba(255, 255, 255, 0.3) 50%, transparent 100%);
  width: 200%;
  height: 200%;
  animation: wave 3s linear infinite;
}

.animate-wave-delayed {
  background: linear-gradient(45deg, transparent 0%, rgba(255, 255, 255, 0.3) 50%, transparent 100%);
  width: 200%;
  height: 200%;
  animation: wave 3s linear infinite;
  animation-delay: 1.5s;
}

/* Add to your Tailwind config if using Tailwind */
@layer utilities {
  .animate-wave {
    animation: wave 3s linear infinite;
  }
  .animate-wave-delayed {
    animation: wave 3s linear infinite;
    animation-delay: 1.5s;
  }
}